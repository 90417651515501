<template>
	<div class="login">
		<div class="login__content">
			<Form />
			<div class="login__art">
				<figure>
					<img src="@/assets/logo-gradiente.svg" />
				</figure>
			</div>
		</div>
	</div>
</template>

<script>
import Form from '@/components/unique/Login/Form'
export default {
	components: { Form },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.login {
	display: flex;
	height: 100%;
	&__content {
		width: 100%;
		display: flex;
	}
	&__art {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		padding: 50px;
		background: linear-gradient(
			224.01deg,
			#3edceb 11.21%,
			#2594ff 62.63%,
			#5533ff 102.2%
		);
		@media (max-width: $sm) {
			display: none;
		}
	}
}
</style>

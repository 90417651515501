<template>
	<div class="login-form">
		<figure>
			<img src="@/assets/logo.svg" />
		</figure>
		<p>Bem vindo ao novo sistema da Signa</p>

		<v-form class="login-form__form" @submit.prevent="login">
			<v-text-field
				label="Digite seu e-mail"
				filled
				background-color="rgba(0, 0, 0, 0.03)"
				v-model="email"
				:disabled="loading"
			/>

			<v-text-field
				v-model="password"
				label="Digite sua senha"
				filled
				background-color="rgba(0, 0, 0, 0.03)"
				:type="show ? 'text' : 'password'"
				:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
				@click:append="show = !show"
				:disabled="loading"
			/>

			<v-btn
				type="submit"
				depressed
				block
				color="#1E90FF"
				style="color: #fff;"
				x-large
				:disabled="loading"
			>
				<v-progress-circular v-if="loading" indeterminate color="#1e90ff" />
				{{ !loading ? 'Fazer Login' : '' }}
			</v-btn>
		</v-form>

		<span>ou</span>

		<v-btn
			depressed
			block
			outlined
			color="primary"
			x-large
			style="font-weight: 500; text-transform: uppercase; font-size: 16px; width: 100%"
			@click="loginWithGoogle"
			:disabled="loading"
		>
			<v-img
				src="@/assets/icons/google.svg"
				max-width="40"
				style="margin-right: 10px;"
			/>
			{{
				loading ? 'Fazendo login com o Google...' : 'Faça login com o Google'
			}}
		</v-btn>

		<div class="login-form__pendencies">
			<b>
				<img src="@/assets/icons/check.svg" /> O pendências está de cara nova</b
			>
			<p>
				Estamos trazendo uma nova forma de gestão de pendências, de uma forma
				simples, fácil e organizada.
			</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data: () => ({
		email: '',
		password: '',
		show: false,
	}),
	computed: {
		...mapGetters(['loading']),
	},
	methods: {
		async login() {
			const { email, password } = this
			try {
				await this.$store.dispatch('user/login', {
					email,
					password,
				})

				this.$router.push({ name: 'dashboard' })
			} catch (e) {
				const { message } = e.response.data
				this.$store.dispatch('setSnackbar', { status: true, message })
			}
		},
		async loginWithGoogle() {
			try {
				const GoogleUser = await this.$gAuth.signIn()
				const { access_token } = await GoogleUser.getAuthResponse()
				// console.log(access_token)
				// const { Ad, $t, dK, OT } = await GoogleUser.getBasicProfile()

				// const user = {
				// 	google_id: OT,
				// 	name: Ad,
				// 	email: $t,
				// 	avatar: dK,
				// }

				await this.$store.dispatch('user/loginGoogle', {
					access_token,
				})

				this.$router.push({ name: 'dashboard' })
			} catch (e) {
				const { message } = e.response.data

				this.$store.dispatch('setSnackbar', {
					status: true,
					message:
						message || 'Você não tem autorização para acessar esse sistema.',
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.login-form {
	width: 50%;
	padding: 102px 178px 0 71px;
	@media (max-width: $sm) {
		width: 100%;
		padding: 30px;
	}

	figure {
		@media (max-width: $sm) {
			display: flex;
			justify-content: center;
		}
	}

	p {
		margin-top: 45px;
		font-weight: 500;
		color: rgb(38, 40, 59, 0.8);
		font-size: 20px;
		@media (max-width: $sm) {
			margin-top: 25px;
			text-align: center;
		}
	}
	span {
		display: flex;
		flex-direction: row;
		font-size: 20px;
		line-height: 23px;
		margin-bottom: 24px;
		&:before,
		&:after {
			content: '';
			flex: 1 1;
			border-bottom: 1px solid #c4c7d7;
			margin: auto;
		}
		&:before {
			margin-right: 10px;
		}
		&:after {
			margin-left: 10px;
		}
	}
	&__form {
		margin-top: 31.64px;
		margin-bottom: 30px;
	}
	&__pendencies {
		margin-top: 35px;
		b {
			display: flex;
			font-weight: 500;
			font-size: 16px;
			line-height: 52px;
			text-transform: uppercase;
			img {
				margin-right: 10px;
			}
		}
		p {
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			margin-top: 3px;
		}
		span {
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #717786;
		}
	}
}
</style>
